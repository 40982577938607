import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Chat from 'Services/Chat';
import useEventListener from 'Hooks/useEventListener';

const LeaveRoomModal = ({ opened = false }) => {
    const [isOpen, setIsOpen] = useState(opened);
    const [roomId, setRoomId] = useState('');
    const [loading, setLoading] = useState(false);

    useEventListener(
        'leave_room',
        (e: any) => {
            setRoomId(e.detail.id);
            setLoading(false);
            setIsOpen(true);
        },
        window,
    );

    const handleClose = () => {
        setLoading(false);
        setIsOpen(false);
    };

    const handleSubmit = () => {
        setLoading(true);

        Chat.leaveRoom(roomId)
            .then(() => {
                setIsOpen(false);
                setLoading(false);
            })
            .catch(console.error);
    };

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Are you sure you want to leave "{Chat.room(roomId)?.name}"?</DialogTitle>
            <DialogActions>
                <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
                <LoadingButton onClick={handleSubmit} loading={loading}>
                    Leave
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(LeaveRoomModal);
