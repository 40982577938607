import React, { useCallback, useState, useRef } from 'react';
import { IconButton, Grid, TextField, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import Chat from 'Services/Chat';
import { isTouch } from 'Services/Device';

import Emoji from './Emoji';

import style from './style.module.css';
import classNames from 'classnames';
import { isIframeMode } from 'Services/Params';

const MsgInput: React.FC<{ roomId: string }> = ({ roomId }) => {
    const [msg, setMsg] = useState('');
    const [focusedOnInput, setFocusedOnInput] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const viewWidth = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

    const viewHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

    const handleTouchStart = (e: any) => {
        document.getElementById('msginput-input')?.focus();
    }

    const handleTouchEnd = (e: any) => {
        document.getElementById('msginput-input')?.focus();
        handleSend(e);
    }

    const handleFocus = (e: any) => {
        setFocusedOnInput(true);

        setTimeout(() => {
            if (ref.current && isIframeMode() && viewWidth > viewHeight) {
                ref.current.scrollTop = 0;
            }
        }, 300);
    }

    const handleBlur = (e: any) => {
        setFocusedOnInput(false);
    }

    const handleSend = (e:any) => {
        setMsg('');

        const trimmedMsg = msg.trim();

        if (!trimmedMsg) {
            return;
        }

        Chat.room(roomId)?.history.publish(msg).then().catch(console.error);

        e.preventDefault();
    };

    const msgHandleChange = (e: any) => {
        setMsg(e.target.value);
    };

    const enterEmoji = useCallback((item: string) => {
        setMsg((prev: string) => [prev, item].join(''));
    }, []);

    return (
        <Grid
            ref={ref}
            container
            spacing={2}
            className={classNames(style.root, {
                [style.rootWideScreen]: focusedOnInput && isIframeMode() && viewWidth > viewHeight,
            })}
        >
            <Grid item xs={10} sx={{ position: 'relative' }}>
                <TextField
                    label="Message"
                    className={style.msgField}
                    value={msg}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={msgHandleChange}
                    onKeyUp={(e: any) => {
                        if (e.keyCode === 13 && !e.shiftKey) {
                            handleSend(e);
                        }

                        e.preventDefault();
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Emoji onSelect={enterEmoji} />
                            </InputAdornment>
                        ),
                    }}
                    id="msginput-input"
                />
            </Grid>
            <Grid item xs={2} className={style.sendBtnWrapper}>
                <label htmlFor="msginput-input">
                    <IconButton
                        className={style.sendBtn}
                        size="large"
                        onClick={!isTouch() ? handleSend : ()=>{}}
                        onTouchStartCapture={isTouch() ? handleTouchStart : ()=>{}}
                        onTouchEndCapture={isTouch() ? handleTouchEnd : ()=>{}}
                    >
                        <SendIcon
                            fontSize="large"
                            sx={{
                                marginRight: '-4px',
                                color: msg ? 'var(--sendBtnBg)' : 'default',
                            }}
                        />
                    </IconButton>
                </label>
            </Grid>
        </Grid>
    );
};

export default React.memo(MsgInput);
