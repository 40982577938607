import React, { useCallback, useMemo, useState } from 'react';
import { Box, Menu } from '@mui/material';
import classNames from 'classnames';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

import style from './style.module.css';
import list from './list';

const Emoji: React.FC<{ onSelect: (item: string) => void }> = ({ onSelect }) => {
    const [opened, setOpened] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = (e: any) => {
        setAnchorEl(e.currentTarget);
        setOpened(true);
    };

    const handleClose = () => {
        setOpened(false);
    };

    const handleClick = useCallback((e: any) => {
        const item = list[Number(e.target.dataset.index)];

        if (item) {
            onSelect(item);
        }
    }, []);

    const emojis = useMemo(
        () =>
            list.map((item: string, i) => (
                <div key={item} className={style.item} data-index={i} onClick={handleClick}>
                    {item}
                </div>
            )),
        [],
    );

    return (
        <Box className={style.root}>
            <SentimentSatisfiedAltIcon
                className={classNames(style.openIcon, { [style.openIcon_opened]: opened })}
                onClick={handleOpen}
            />

            <Menu id="basic-menu" anchorEl={anchorEl} open={opened} onClose={handleClose} className={style.menuRoot}>
                <Box className={style.card}>{emojis}</Box>
            </Menu>
        </Box>
    );
};

export default React.memo(Emoji);
