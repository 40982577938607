import React, { useEffect } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import classNames from 'classnames';

import Chat from 'Services/Chat';
import Format from 'Utils/Format';
import useForceUpdate from 'Hooks/useForceUpdate';

import UnreadIcon from './UnreadIcon';

import style from './style.module.css';

const Room: React.FC<{
    id: string;
    selected: boolean;
    onSelect: (roomId: string) => void;
}> = ({ id, selected, onSelect }) => {
    const forceUpdate = useForceUpdate();

    const handleEditClick = () => {
        window.dispatchEvent(new CustomEvent('edit_room', { detail: { id } }));
    };

    // Subscribe to room updates
    useEffect(() => {
        const room = Chat.room(id);

        if (!room) {
            return;
        }

        const events = [
            room.Events.INFO_UPDATED,
            room.Events.MEMBERS_ADDED,
            room.Events.MEMBERS_REMOVED,
            room.Events.PARTICIPANT_META_UPDATED,
        ];

        room.on(events, forceUpdate);
        room.history.on(room.history.Events.MESSAGE, forceUpdate);

        return () => {
            room.off(events, forceUpdate);
            room.history.off(room.history.Events.MESSAGE, forceUpdate);
        };
    }, []);

    const room = Chat.room(id);

    if (!room) {
        return null;
    }

    return (
        <Box className={style.root}>
            <Button
                variant="text"
                className={style.btn}
                onClick={() => {
                    onSelect(room.id);
                }}
            >
                <Box className={style.labels}>
                    <Box className={style.nameAndDate}>
                        <Box className={style.nameWrapper}>
                            <div className={style.roomName}>{room.name || room.id}</div>
                        </Box>
                        <Box className={style.date}>
                            {new Date(room.updated_at).toLocaleDateString('en-GB', {
                                month: 'numeric',
                                day: 'numeric',
                            })}
                        </Box>
                    </Box>

                    <Box className={classNames(style.names, { [style.selected]: selected })}>
                        {room.members
                            .filter((member) => member !== Chat.user?.id)
                            .map(
                                (member) =>
                                    (room.meta(member)?.username || Format.userId(member)) +
                                    (room.creator === member ? '(owner)' : ''),
                            )
                            .join(', ')}
                        &nbsp;
                    </Box>

                    <UnreadIcon history={room.history} />
                </Box>
            </Button>

            <IconButton component="span" onClick={handleEditClick} className={style.editBtn}>
                <EditIcon color="action" fontSize="small" sx={{ color: 'var(--iconLight)' }} />
            </IconButton>
        </Box>
    );
};

export default React.memo(Room);
