import React, { useCallback, useEffect, useState } from 'react';
import { ChatEvents, Room as RoomType, RoomEvents, HistoryEvents } from '@sscale/chatsdk';
import { Card, CardContent, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import Chat from 'Services/Chat';
import useForceUpdate from 'Hooks/useForceUpdate';

import Room from './Room';
import Header from './Header';
import Board from './Board';
import Notification from './Notification';

import style from './style.module.css';

function Rooms() {
    const forceUpdate = useForceUpdate();
    const [selectedRoomId, setSelectedRoomId] = useState('');

    const handleClick = useCallback((roomId: string) => {
        setSelectedRoomId(roomId);
    }, []);

    const handleBackBtnClick = () => {
        setSelectedRoomId('');
    };

    // Subscribe to rooms updates
    useEffect(() => {
        const events = [
            ChatEvents.ROOM_ADDED,
            ChatEvents.ROOM_REMOVED,
            RoomEvents.INFO_UPDATED,
            RoomEvents.MEMBERS_ADDED,
            RoomEvents.MEMBERS_REMOVED,
            HistoryEvents.MESSAGE,
        ];

        Chat.on(events, forceUpdate);

        return () => {
            Chat.off(events, forceUpdate);
        };
    }, []);

    const sort = useCallback((room1: RoomType, room2: RoomType) => {
        // @ts-ignore
        return new Date(room2.updated_at) - new Date(room1.updated_at);
    }, []);

    useEffect(() => {
        if (!Chat.room(selectedRoomId)) {
            setSelectedRoomId('');
        }
    });

    return (
        <>
            <div className={style.root}>
                <div
                    className={style.roomsContainer}
                    style={{
                        visibility: !Chat.user?.id ? 'hidden' : 'visible',
                    }}
                >
                    <Card className={style.roomsCard}>
                        <Notification selectedRoomId={selectedRoomId} />

                        <Header />

                        <div className={style.content}>
                            <CardContent className={style.list}>
                                {Chat.rooms.sort(sort).map((room) => (
                                    <Room
                                        key={room.id}
                                        id={room.id}
                                        onSelect={handleClick}
                                        selected={selectedRoomId === room.id}
                                    />
                                ))}
                            </CardContent>
                        </div>
                    </Card>
                </div>

                <div className={style.boardContainer} data-show={Boolean(selectedRoomId)}>
                    {selectedRoomId && Chat.room(selectedRoomId) && <Board roomId={selectedRoomId} />}

                    {selectedRoomId && (
                        <IconButton aria-label="back" className={style.backBtn} onClick={handleBackBtnClick}>
                            <ClearIcon fontSize="small" sx={{ color: 'var(--iconLight)' }} />
                        </IconButton>
                    )}
                </div>
            </div>
        </>
    );
}

export default React.memo(Rooms);
