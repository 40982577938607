import React from 'react';
import classNames from 'classnames';
import { Popover } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import Chat from 'Services/Chat';
import Format from 'Utils/Format';
import useEventListener from 'Hooks/useEventListener';

import style from './style.module.css';

const colors = ['#fde4cf', '#ffcfd2', '#f1c0e8', '#cfbaf0', '#a3c4f3', '#90dbf4', '#8eecf5', '#98f5e1', '#b9fbc0'];

const MsgPopover: React.FC<{ roomId: string }> = ({ roomId }) => {
    const [state, setState] = React.useState<{ x: number; y: number; timestamp: string } | null>(null);

    useEventListener(
        'msg_popover',
        (e: any) => {
            setState(e.detail);
        },
        window,
    );

    const handleClose = () => {
        setState(null);
    };

    const room = Chat.room(roomId);

    if (!room) {
        return null;
    }

    if (!state) {
        return null;
    }

    const readBy = room.history.readBy(state.timestamp);

    return (
        <Popover
            open
            anchorReference="anchorPosition"
            anchorPosition={{ top: state.y + 5, left: state.x + 5 }}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div className={style.root}>
                <div className={style.menu}>
                    <div className={style.btn}>
                        <EditIcon fontSize="small" />
                        Edit (soon)
                    </div>
                    <div className={style.btn}>
                        <DeleteForeverIcon fontSize="small" />
                        Remove (soon)
                    </div>

                    {Boolean(readBy.length) && (
                        <div className={classNames(style.btn, style.seenBtn)}>
                            <DoneAllIcon fontSize="small" />
                            <span>{readBy.length} Seen</span>
                            <ExpandMoreIcon style={{ marginLeft: 'auto' }} fontSize="small" />
                        </div>
                    )}
                </div>

                {Boolean(readBy.length) && (
                    <div className={style.readList}>
                        {readBy.map((user, i) => (
                            <div key={user} className={style.readListItem} style={{ color: colors[i % colors.length] }}>
                                <FaceIcon fontSize="small" />
                                <span style={{ color: 'var(--mainTextColor)' }}>
                                    {room.meta(user)?.username || Format.userId(user)}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Popover>
    );
};

export default React.memo(MsgPopover);
