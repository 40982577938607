const observers = new WeakMap();
const callbacks = new WeakMap();

function Observer(root: Element) {
    const opts = {
        root,
        rootMargin: '0px',
        threshold: 0.9,
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const clb = callbacks.get(entry.target);

            if (clb) {
                clb(entry.isIntersecting);
            }
        });
    }, opts);

    return observer;
}

function observe(root: Element, el: Element, callback: Function) {
    if (!observers.has(root)) {
        observers.set(root, Observer(root));
    }

    const observer = observers.get(root);

    callbacks.set(el, callback);
    observer.observe(el);
}

function unobserve(root: Element, el: Element) {
    if (!observers.has(root)) {
        return;
    }

    const observer = observers.get(root);

    callbacks.delete(el);
    observer.unobserve(el);
}

function useObserver() {
    return { observe, unobserve };
}

export default useObserver;
