import React, { useEffect, useRef, useState } from 'react';
import { Card } from '@mui/material';
import { HistoryEvents, MessageType } from '@sscale/chatsdk';

import Chat from 'Services/Chat';
import Format from 'Utils/Format';

import style from './style.module.css';

const Notification: React.FC<{ selectedRoomId?: string }> = ({ selectedRoomId }) => {
    const [notificationOpened, setNotificationOpened] = useState(false);
    const [lastMsg, setLastMsg] = useState<MessageType>();
    const timer = useRef<any>();

    useEffect(() => {
        const handleMsg = (msg: MessageType) => {
            if (msg.room === selectedRoomId) {
                return;
            }

            setLastMsg(msg);
            setNotificationOpened(true);

            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setNotificationOpened(false);
            }, 2000);

            try {
                const soundElem = document.getElementById('new-message-sound');
                //@ts-ignore
                soundElem.play();
            } catch (e) {
                console.log('Error - msg sound', e);
            }

            window.parent.postMessage(JSON.stringify(msg), '*');
        };

        Chat.on(HistoryEvents.MESSAGE, handleMsg);

        return () => {
            Chat.off(HistoryEvents.MESSAGE, handleMsg);
        };
    }, [selectedRoomId]);

    const show = notificationOpened && Chat.user?.id !== lastMsg?.author;

    return (
        <Card className={style.root} data-show={show}>
            {lastMsg && (
                <>
                    <div className={style.group}>{Chat.room(lastMsg.room)?.name}</div>
                    <div className={style.msg}>
                        <span className={style.author}>
                            {Chat.room(lastMsg.room)?.meta(lastMsg.author)?.username || Format.userId(lastMsg.author)}
                            :&nbsp;
                        </span>
                        <span>{lastMsg.content}</span>
                    </div>
                </>
            )}
        </Card>
    );
};

export default React.memo(Notification);
