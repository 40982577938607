function useDateLabel(timestamp: string | Date) {
    const date = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(Date.now() - 86400000);

    if (today.toDateString() === date.toDateString()) {
        return 'Today';
    }

    if (yesterday === date) {
        return 'Yesterday';
    }

    return date.toLocaleDateString('en-GB', { month: 'long', day: 'numeric' });
}

export default useDateLabel;
