import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { isIframeMode, isDarkTheme } from './Services/Params';

import './index.module.css';

const $root = document.getElementById('root');
const $html = document.getElementsByTagName('html')[0];

if (isIframeMode()) {
    $html.setAttribute('class', 'iframe-mode');
}

if (isDarkTheme()) {
    document.querySelector('html')?.setAttribute('data-theme', 'dark');
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    $root,
);
