import React from 'react';
import { Box, Card } from '@mui/material';

import Chat from 'Services/Chat';

import Messages from './Messages';
import MsgInput from './MsgInput';
import MsgPopover from './MsgPopover';

import style from './style.module.css';

const Board: React.FC<{ roomId: string }> = ({ roomId }) => {
    const room = Chat.room(roomId);

    return (
        <Card className={style.root}>
            <div className={style.title}>
                {room?.name}
                &nbsp;
            </div>

            <Messages roomId={roomId} />

            <Box className={style.actions}>
                <MsgInput roomId={roomId} />
            </Box>

            <MsgPopover roomId={roomId} />
        </Card>
    );
};

export default React.memo(Board);
