export default [
    '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '🥲', '😊', '😇', '🙂', '🙃',
    '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪',
    '🤨', '🧐', '🤓', '😎', '🥸', '🤩', '🥳', '😏', '😒', '😞', '😔', '😟', '😕',
    '🙁', '😣', '😖', '😫', '😩', '🥺', '😢', '😭', '😤', '😠', '😡', '🤬', '🤯',
    '😳', '🥵', '🥶', '😱', '😨', '😰', '😥', '😓', '🤗', '🤔', '🤭', '🤫', '🤥',
    '😶', '😐', '😑', '😬', '🙄', '😯', '😦', '😧', '😮', '😲', '🥱', '😴', '🤤',
    '😪', '😵', '🤐', '🥴', '🤢', '🤮', '🤧', '😷', '🤒', '🤕', '🤑', '🤠', '😈',
    '👿', '👹', '👺', '🤡', '💩', '👻', '💀', '👽', '👾', '🤖', '🎃', '😺', '😸',
    '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '👋', '🤚', '🖐', '✋', '🖖', '👌',
    '🤌', '🤏', '🤞', '🤟', '🤘', '🤙', '👈', '👉', '👆', '🖕', '👇', '👍', '🥜',
    '👎', '✊', '👊', '🤛', '🤜', '👏', '🙌', '👐', '🤲', '🤝', '🙏', '💅', '🤳',
    '💪', '🦾', '🦵', '🦿', '🦶', '👣', '👂', '🦻', '👃', '🫀', '🫁', '🧠', '🦷',
    '🦴', '👀', '👁', '👅', '👄', '💋', '🩸', '👶', '👧', '🧒', '👦', '👩', '🧑',
    '👨', '🗣', '👤', '👥', '🫂', '👋🏼', '🤚🏼', '🖐🏼', '✋🏼', '🖖🏼', '🍯', '🥛', '🍼',
    '👌🏼', '🤌🏼', '🤏🏼', '✌🏼', '🤞🏼', '🤟🏼', '🤘🏼', '🤙🏼', '👈🏼', '👉🏼', '👆🏼', '🖕🏼', '👇🏼',
    '☝🏼', '👍🏼', '👎🏼', '✊🏼', '👊🏼', '🤛🏼', '🤜🏼', '👏🏼', '🙌🏼', '👐🏼', '🤲🏼', '🙏🏼', '✍🏼',
    '💅🏼', '🤳🏼', '💪🏼', '🦵🏼', '🦶🏼', '👂🏼', '🦻🏼', '👃🏼', '👶🏼', '👧🏼', '🧒🏼', '👦🏼', '👩🏼',
    '🧑🏼', '👨🏼', '🧔🏼', '👵🏼', '🧓🏼', '👴🏼', '🐶', '🐱', '🐭', '🐹', '🐰', '🫖', '🍵',
    '🦊', '🐻', '🐼', '🐨', '🐯', '🦁', '🐮', '🐷', '🐽', '🐸', '🐵', '🙈', '🧃',
    '🙉', '🙊', '🐒', '🐔', '🐧', '🐦', '🐤', '🐣', '🐥', '🦆', '🦅', '🦉', '🦇',
    '🐺', '🐗', '🐴', '🦄', '🐝', '🪱', '🐛', '🦋', '🐌', '🐞', '🐜', '🪰', '🪲',
    '🪳', '🦟', '🦗', '🕷', '🕸', '🦂', '🐢', '🐍', '🦎', '🦖', '🦕', '🐙', '🦑',
    '🦐', '🦞', '🦀', '🐡', '🐠', '🐟', '🐬', '🐳', '🐋', '🦈', '🐊', '🐅', '🐆',
    '🦓', '🦍', '🦧', '🦣', '🐘', '🦛', '🦏', '🐪', '🐫', '🦒', '🦘', '🦬', '🐃',
    '🐂', '🐄', '🐎', '🐖', '🐏', '🐑', '🦙', '🐐', '🦌', '🐕', '🐩', '🦮', '🥤',
    '🐈', '🪶', '🐓', '🦃', '🦤', '🦚', '🦜', '🦢', '🦩', '🕊', '🐇', '🦝', '🧋',
    '🦨', '🦡', '🦫', '🦦', '🦥', '🐁', '🐀', '🐿', '🦔', '🐾', '🐉', '🐲', '🌵',
    '🎄', '🌲', '🌳', '🌴', '🪵', '🌱', '🌿', '🍀', '🎍', '🪴', '🎋', '🍃', '🧊',
    '🍂', '🍁', '🍄', '🐚', '🪨', '🌾', '💐', '🌷', '🌹', '🥀', '🌺', '🌸', '🌼',
    '🌻', '🌞', '🌝', '🌛', '🌜', '🌚', '🌕', '🌖', '🌗', '🌘', '🌑', '🌒', '🌓',
    '🌔', '🌙', '🌎', '🌍', '🌏', '🪐', '💫', '🌟', '✨', '💥', '🍶', '🍻', '🥂',
    '🔥', '🌪', '🌈', '🌤', '🌥', '🌦', '🌧', '⛈', '🌩', '🌨', '🍷', '🥃', '🍸',
    '🌬', '💨', '💧', '💦', '🍏', '🍎', '🍐', '🍊', '🍋', '🍺', '🍹', '🧉', '🍾',
    '🍌', '🍉', '🍇', '🍓', '🫐', '🍈', '🍒', '🍑', '🥭', '🍍', '🥥', '🥝', '🍅',
    '🍆', '🥑', '🥦', '🥬', '🥒', '🌶', '🫑', '🌽', '🥕', '🫒', '🧄', '🧅', '🥔',
    '🍠', '🥐', '🥯', '🍞', '🥖', '🥨', '🧀', '🥚', '🍳', '🧈', '🥞', '🧇', '🥓',
    '🥩', '🍗', '🍖', '🌭', '🍔', '🍟', '🍕', '🫓', '🥪', '🥙', '🧆', '🌮', '🌰',
    '🌯', '🫔', '🥗', '🥘', '🫕', '🥫', '🍝', '🍜', '🍲', '🍛', '🍣', '🍱', '🥟',
    '🦪', '🍤', '🍙', '🍚', '🍘', '🍥', '🥠', '🥮', '🍢', '🍡', '🍧', '🍨', '🍦',
    '🥧', '🧁', '🍰', '🎂', '🍮', '🍭', '🍬', '🍫', '🍿', '🍩', '🍪',
];
