import React, { useState, useEffect } from 'react';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import qs from 'query-string';

import Chat from 'Services/Chat';

const UserIdModal = () => {
    const [id, setId] = useState('');
    const [connecting, setConnecting] = useState(false);

    const handleSubmit = async (uid: string) => {
        if (!uid) {
            return;
        }

        try {
            setConnecting(true);

            // For demo purposes only, own auth solution should be implemented
            const data = await fetch(`https://chat.management.sceenic.co/token`, {
                method: 'POST',
                body: JSON.stringify({ user_id: uid }),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.json());

            const token = data.token;
            await Chat.connect(token);

            // setIsOpen(false);
            // setConnecting(false);
        } catch (e) {
            console.error('Connect failed,', e);
        }
    };

    useEffect(() => {
        const URL_PARAMS = qs.parse(window.location.search);

        if (URL_PARAMS.uid && typeof URL_PARAMS.uid === 'string') {
            setId(URL_PARAMS.uid);
            handleSubmit(URL_PARAMS.uid);
        }
    }, []);

    return (
        <Dialog open maxWidth="xs" sx={{ '& .MuiDialog-paper': { width: '100%' } }}>
            <DialogTitle>Enter User ID</DialogTitle>
            <DialogContent>
                <TextField
                    label="User ID"
                    variant="standard"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    onKeyUp={(e: any) => {
                        if (e.keyCode === 13) {
                            handleSubmit(id);
                        }
                    }}
                    fullWidth
                    autoFocus
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={() => handleSubmit(id)} loading={connecting}>
                    Connect
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(UserIdModal);
