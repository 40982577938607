import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Chip } from '@mui/material';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import LoadingButton from '@mui/lab/LoadingButton';
import classNames from 'classnames';

import Chat from 'Services/Chat';
import Format from 'Utils/Format';
import useEventListener from 'Hooks/useEventListener';

import AddUsersInput from '../_/AddUsersInput';

import style from './style.module.css';

const RoomEditorModal = () => {
    const [roomId, setRoomId] = useState('');
    const [roomName, setRoomName] = useState('');
    const [newMembers, setNewMembers] = useState([]);
    const [removedMembers, setRemovedMembers] = useState<Array<string>>([]);
    const [roomEditorIsOpened, setRoomEditorIsOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    useEventListener(
        'edit_room',
        (e: any) => {
            const room = Chat.room(e.detail.id);
            if (!room) {
                return;
            }

            setRoomId(room.id);

            setRoomName(room.name ?? '');
            setNewMembers([]);
            setRemovedMembers([]);

            setLoading(false);
            setRoomEditorIsOpened(true);
        },
        window,
    );

    const handleClose = () => {
        setRoomEditorIsOpened(false);
        setRoomId('');
    };

    const handleSubmit = () => {
        setLoading(true);

        const room = Chat.room(roomId);
        if (!room) {
            return;
        }

        const removedMembersIds = removedMembers.map((item) => item);

        const operations = [];

        if (newMembers.length) {
            operations.push(room.addMembers(newMembers));
        }
        if (removedMembersIds.length) {
            operations.push(room.removeMembers(removedMembersIds));
        }
        if (roomName !== room.name) {
            operations.push(room.rename(roomName));
        }

        Promise.all(operations)
            .then(() => {
                handleClose();
            })
            .catch(console.error);
    };

    const handleLeave = () => {
        window.dispatchEvent(new CustomEvent('leave_room', { detail: { id: roomId } }));
        handleClose();
    };

    const room = Chat.room(roomId);

    if (!room) {
        return null;
    }

    const activeParticipants = room.members
        .filter((member) => member !== Chat.user?.id)
        .filter((member) => !removedMembers.some((item) => item === member));

    return (
        <Dialog
            open={roomEditorIsOpened}
            onClose={handleClose}
            maxWidth="xs"
            sx={{ '& .MuiDialog-paper': { width: '100%' } }}
        >
            <DialogTitle>
                <div className={style.title}>
                    <div>Room editor</div>
                    <Chip
                        icon={<MeetingRoomIcon />}
                        color="error"
                        label="Leave group"
                        variant="outlined"
                        onClick={handleLeave}
                        clickable
                        className={style.leave}
                    />
                </div>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="Room name"
                    variant="standard"
                    value={roomName}
                    onChange={(e) => setRoomName(e.target.value)}
                    onKeyUp={(e: any) => {
                        if (e.keyCode === 13) {
                            handleSubmit();
                        }
                    }}
                    fullWidth
                />

                {room.creator === Chat.user?.id && (
                    <div className={style.participants}>
                        <div className={style.participants__title}>
                            Participants: {!activeParticipants?.length && <span>🤔</span>}
                        </div>

                        {!!activeParticipants?.length && (
                            <div className={style.participants__list}>
                                {activeParticipants.map((member: any) => (
                                    <Chip
                                        className={style.chip}
                                        color="primary"
                                        label={room.meta(member)?.username || Format.userId(member)}
                                        variant="outlined"
                                        onDelete={() => {
                                            setRemovedMembers((prevState) => {
                                                return [...prevState, member];
                                            });
                                        }}
                                        key={`chip-${member}`}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {!!removedMembers.length && (
                    <div className={classNames(style.participants, style.removedParticipants)}>
                        <div className={style.participants__title}>Removed participants:</div>

                        <div className={style.participants__list}>
                            {removedMembers
                                .filter((member) => member !== Chat.user?.id)
                                .map((member) => (
                                    <Chip
                                        className={style.chip}
                                        color="warning"
                                        label={room.meta(member)?.username || Format.userId(member)}
                                        variant="outlined"
                                        onDelete={() => {
                                            setRemovedMembers((prevState) => {
                                                return prevState.filter((item) => item !== member);
                                            });
                                        }}
                                        key={`chip-${member}`}
                                    />
                                ))}
                        </div>
                    </div>
                )}

                <AddUsersInput
                    className={style.participants}
                    label="Add Participants"
                    onChange={setNewMembers}
                    value={newMembers}
                />

                <div className={style.description}>For any changes please click "Update room"</div>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={handleSubmit} loading={loading}>
                    Update Room
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(RoomEditorModal);
