import React, { useState } from 'react';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Chat from 'Services/Chat';
import useEventListener from 'Hooks/useEventListener';

import AddUsersInput from '../_/AddUsersInput';

function AddRoomModal() {
    const [ids, setIds] = useState([]);
    const [roomName, setRoomName] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [adding, setAdding] = useState(false);

    useEventListener(
        'add_room',
        () => {
            setAdding(false);
            setIds([]);
            setRoomName('');
            setIsOpen(true);
        },
        window,
    );

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSubmit = () => {
        setAdding(true);

        Chat.createRoom(ids, roomName)
            .then(() => {
                handleClose();
            })
            .catch(console.error);
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" sx={{ '& .MuiDialog-paper': { width: '100%' } }}>
            <DialogTitle>Create new room</DialogTitle>
            <DialogContent>
                <AddUsersInput
                    label="Users"
                    onChange={setIds}
                    value={ids}
                    onKeyUp={(e: any) => {
                        if (e.keyCode === 13) {
                            handleSubmit();
                        }
                    }}
                    autoFocus
                />
                <br />
                <TextField
                    label="Room name"
                    variant="standard"
                    value={roomName}
                    onChange={(e) => setRoomName(e.target.value)}
                    onKeyUp={(e: any) => {
                        if (e.keyCode === 13) {
                            handleSubmit();
                        }
                    }}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={handleSubmit} loading={adding}>
                    Create
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AddRoomModal;
