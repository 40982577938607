import React, { useEffect, useState, useRef } from 'react';
import { TextField, Autocomplete, IconButton, Button,  Dialog, DialogContent, DialogActions } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Users, { UsersType } from 'Services/Users';
import useForceUpdate from 'Hooks/useForceUpdate';
import useEventListener from 'Hooks/useEventListener';

import classNames from 'classnames';

import style from './style.module.css';

// @ts-ignore
function AddUsersInputAutocomplete({ onChange, value, onKeyUp, label, className }) {
    const forceUpdate = useForceUpdate();
    const [focusedOnInput, setFocusedOnInput] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const handleFocus = (e: any) => {
        setFocusedOnInput(true);
        
        setTimeout(() => {
            if (ref.current) {
                ref.current.scrollTop = 0;
            }
        }, 300);
    }

    const handleBlur = (e: any) => {
        setFocusedOnInput(false);
    }

    const handleBack = (e: any) => {
        setFocusedOnInput(false);
    }

    useEffect(() => {
        Users.update();
    }, []);

    useEventListener('users_update', forceUpdate, window);

    function handleChange(_: any, users: UsersType) {
        onChange(users.map((user) => user.id));
    }

    value = value.map((id: number) => Users.list.find((user) => user.id === id)).filter(Boolean);

    const result = (
        <div
            ref={ref}
            className={classNames({
                [style.rootWideScreen]: focusedOnInput,
            })}
        >
            <IconButton
                className={style.back}
                sx={{
                    display: focusedOnInput ? 'flex' : 'none',
                }}
                size="large"
                onClick={handleBack}
            >
                <ArrowBackIcon
                    fontSize="medium"
                    sx={{
                        marginRight: '-4px',
                        color: 'var(--iconLight)',
                    }}
                />
            </IconButton>
            <Autocomplete
                className={classNames(style.autocomplete, className)}
                multiple
                fullWidth={focusedOnInput}
                id="tags-standard"
                options={Users.list}
                value={value}
                loading={!Users.list.length}
                onChange={handleChange}
                onKeyUp={onKeyUp}
                onOpen={handleFocus}
                onBlur={handleBlur}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} variant="standard" label={label} />}
            />
        </div>
    );

    if (focusedOnInput) {
        return (
            <Dialog open fullScreen>
                <DialogContent sx={{ paddingTop: 0 }}>
                    {result}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setFocusedOnInput(false)}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return result;
}

export default AddUsersInputAutocomplete;
