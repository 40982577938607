import React, { useState } from 'react';
import { TextField } from '@mui/material';

// @ts-ignore
function AddUsersInputSimple({ onChange, onKeyUp, label, autoFocus, className }) {
    const [value, setValue] = useState('');

    function handleChange(e: any) {
        const value = e.target.value;

        onChange(
            value
                .split(',')
                .map((item: string) => item.trim())
                .filter(Boolean),
        );

        setValue(value);
    }

    return (
        <TextField
            placeholder="ID1,ID2,ID3"
            label={label}
            variant="standard"
            value={value}
            onChange={handleChange}
            onKeyUp={onKeyUp}
            autoFocus={autoFocus}
            fullWidth
            className={className}
        />
    );
}

export default AddUsersInputSimple;
