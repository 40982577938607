import { useEffect, useRef } from 'react';

function useEventListener(eventName: string, handler: any, element: any) {
    const savedHandler = useRef((e: any) => {});

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const target = typeof element === 'string' ? document.querySelector(element) : element;
        if (!target) {
            return;
        }

        const eventListener = (event: any) => savedHandler?.current?.(event);

        target.addEventListener(eventName, eventListener);

        return () => {
            target.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
}

export default useEventListener;
