declare global {
    interface Window {
        AndroidChat: any;
        webkit: any;
        handleMessage: any;
    }
}

let ID = 1;
const REQUESTS: { [key: number]: (err: Error | null, result: any) => void } = {};

export function request(tag: string, data?: any) {
    return new Promise((resolve, reject) => {
        const invocationId = ID++;

        const timeout = setTimeout(() => {
            delete REQUESTS[invocationId];
            reject(new Error('Timeout'));
        }, 5000);

        REQUESTS[invocationId] = (err, result) => {
            console.log(err, result);
            clearTimeout(timeout);
            delete REQUESTS[invocationId];
            err ? reject(err) : resolve(result);
        };

        post(tag, data, invocationId);
    });
}

export function post(tag: string, data?: any, id?: number) {
    const message = JSON.stringify({ id, tag, data });
    console.log('Sending message to external listeners', message);

    if (window.AndroidChat) {
        // android addJavascriptInterface
        window.AndroidChat.postMessage(message);
    } else if (window.webkit?.messageHandlers?.iOSChat) {
        // iOS userContentController
        window.webkit.messageHandlers.iOSChat.postMessage(message);
    } else {
        // iframe postMessage
        window.parent?.postMessage(message, '*');
    }
}

const LISTENERS: { [key: string]: { (data: any): void }[] } = {};

export function on(tag: string, clb: any) {
    if (!tag || !clb) {
        return;
    }

    const listeners = LISTENERS[tag] || [];
    listeners.push(clb);

    LISTENERS[tag] = listeners;
}

export function off(tag: string, clb: any) {
    if (!tag) {
        return;
    }

    if (!clb) {
        LISTENERS[tag] = [];
    } else {
        LISTENERS[tag] = LISTENERS[tag]?.filter((v) => v !== clb);
    }
}

function listener(message: any) {
    console.log('Received external message', message);
    try {
        const { tag, data, id, error } = typeof message === 'string' ? JSON.parse(message) : message;

        if (id) {
            REQUESTS[id]?.(error ? new Error(error) : null, data);
        } else {
            LISTENERS[tag]?.forEach((listener) => listener(data));
        }
    } catch (e) {
        console.error('Message handler failed', e);
    }
}

// method for getting data from ios/android evaluate JS function
window.handleMessage = listener;
// iframe postMessage listener
window.addEventListener('message', (e) => {
    if (e.origin !== window.origin) {
        listener(e.data);
    }
});

export default { request, post, on, off };
