import React, { useEffect } from 'react';
import { CardHeader, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

import Chat from 'Services/Chat';
import useForceUpdate from 'Hooks/useForceUpdate';

import style from './style.module.css';

function Header() {
    const forceUpdate = useForceUpdate();

    const handleEditClick = () => {
        window.dispatchEvent(new CustomEvent('edit_name'));
    };

    const handleAddRoomClick = () => {
        window.dispatchEvent(new CustomEvent('add_room'));
    };

    // Subscribe to connection and user state updates
    useEffect(() => {
        const events = [Chat.Events.RECONNECT, Chat.Events.RECONNECTING, Chat.Events.USER_META_UPDATED];

        Chat.on(events, forceUpdate);

        return () => {
            Chat.off(events, forceUpdate);
        };
    }, []);

    return (
        <CardHeader
            title={
                <Box
                    sx={{
                        fontSize: 16,
                        letterSpacing: 1,
                        marginBottom: '-4px',
                    }}
                >
                    <Box className={style.username}>
                        <Box>
                            {Chat.user?.meta.username || ''}
                            <EditIcon
                                color="action"
                                fontSize="small"
                                className={style.editIcon}
                                sx={{
                                    // color: 'rgba(0, 0, 0, .17)',
                                    marginLeft: 1,
                                    marginBottom: '-4px',
                                    cursor: 'pointer',
                                }}
                                onClick={handleEditClick}
                            />
                        </Box>
                    </Box>

                    {Chat.state === Chat.States.RECONNECTING && (
                        <SyncProblemIcon fontSize="small" className={style.connectionIndicator} />
                    )}
                </Box>
            }
            action={
                <IconButton component="span" onClick={handleAddRoomClick} className={style.addBtn}>
                    <AddIcon sx={{ color: 'var(--iconLight)' }} />
                </IconButton>
            }
            className={style.header}
        />
    );
}

export default React.memo(Header);
