import React, { useEffect } from 'react';
import { History } from '@sscale/chatsdk';

import useForceUpdate from 'Hooks/useForceUpdate';

import style from './style.module.css';

const UnreadIcon: React.FC<{
    history: History;
}> = ({ history }) => {
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        history.on(history.Events.READ_STATUS_UPDATED, forceUpdate);

        return () => {
            history.off(history.Events.READ_STATUS_UPDATED, forceUpdate);
        };
    }, []);

    if (!history.haveUnread()) {
        return null;
    }

    return <div className={style.unreadIcon} />;
};

export default UnreadIcon;
