import React, { useState, useEffect } from 'react';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import qs from 'query-string';

import Chat from 'Services/Chat';
import Format from 'Utils/Format';
import useEventListener from 'Hooks/useEventListener';

const UserNameModal = ({ opened = false }) => {
    const [userName, setUserName] = useState(Chat.user?.meta.username || '');
    const [isOpen, setIsOpen] = useState(opened);
    const [updating, setUpdating] = useState(false);

    useEventListener(
        'edit_name',
        () => {
            setUpdating(false);
            setIsOpen(true);
            setUserName(Chat.user?.meta.username || '');
        },
        window,
    );

    const handleClose = () => {
        if (!Chat.user?.meta.username) {
            return;
        }

        setIsOpen(false);
    };

    const handleSubmit = (userName: string) => {
        if (!userName) {
            return;
        }

        setUpdating(true);

        Chat.setUsername(userName)
            .then(() => {
                setIsOpen(false);
            })
            .catch(console.error);
    };

    useEffect(() => {
        const URL_PARAMS = qs.parse(window.location.search);

        if (URL_PARAMS.uname && typeof URL_PARAMS.uname === 'string') {
            setUserName(URL_PARAMS.uname);
            handleSubmit(URL_PARAMS.uname);
        }
    }, []);


    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth="xs"
            sx={{ '& .MuiDialog-paper': {width: '100%'} }}
        >
            <DialogTitle>Enter your name</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    variant="standard"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    onKeyUp={(e: any) => {
                        if (e.keyCode === 13) {
                            handleSubmit(userName);
                        }
                    }}
                    fullWidth
                    autoFocus
                />

                <div style={{ color: 'var(--textGrayColor)', marginTop: '16px' }}>
                    {Format.userId(Chat.user?.id)}
                </div>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    onClick={() => handleSubmit(userName)}
                    loading={updating}
                >
                    Update
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(UserNameModal);
