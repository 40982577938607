import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Chat from 'Services/Chat';
import { isIframeMode, isDarkTheme } from 'Services/Params';
import 'Services/Users';
import 'Services/API';

import Rooms from 'Components/Rooms';
import UserIdModal from 'Components/Modals/UserIdModal';
import UserNameModal from 'Components/Modals/UserNameModal';
import RoomEditorModal from 'Components/Modals/RoomEditorModal';
import LeaveRoomModal from 'Components/Modals/LeaveRoomModal';
import AddRoomModal from 'Components/Modals/AddRoomModal';

import style from './app.module.css';

const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                container: {
                    alignItems: isIframeMode() ? 'flex-start' : 'center',
                },
            },
        },
    },
    palette: {
      mode: isDarkTheme() ? 'dark' : 'light',
    },
  });

function App() {
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        const handleError = (e: any) => {
            console.error('Chat error,', e);
            setIsConnected(false);
        };

        Chat.on(Chat.Events.ERROR, handleError);
        Chat.on(Chat.Events.CONNECT, () => {
            setIsConnected(true);
        });

        return () => {
            Chat.off();
            Chat.disconnect();
        };
    }, []);

    if (!isConnected) {
        return <ThemeProvider theme={theme}><UserIdModal /></ThemeProvider>;
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Container
                    maxWidth="md"
                    className={style.responsiveContainer}
                    sx={{
                        paddingTop: isIframeMode() ? 0 : 4,
                    }}
                >
                    <Rooms />
                </Container>

                <UserNameModal opened={!Chat.user?.meta.username} />
                <RoomEditorModal />
                <LeaveRoomModal />
                <AddRoomModal />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
