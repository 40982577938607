import React from 'react';

import { isIframeMode, showFakeFriends } from 'Services/Params';

import Autocomplete from './Autocomplete';
import Simple from './Simple';

function AddUsersInput(props: any) {
    if (isIframeMode() || showFakeFriends()) {
        return <Autocomplete {...props} />;
    }

    return <Simple {...props} />;
}

export default AddUsersInput;
