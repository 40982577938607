import ExternalDataBus from './ExternalDataBus';
import { showFakeFriends } from './Params';

export type UsersType = { id: number; name: string }[];

class Users {
    public list: UsersType = [];

    constructor() {
        ExternalDataBus.on('USERS_LIST', this.set);

        if (showFakeFriends()) {
            this.list = [
                { id: 1, name: 'Test1' },
                { id: 2, name: 'Test2' },
                { id: 3, name: 'Test3' },
                { id: 4, name: 'Test4' },
                { id: 5, name: 'Test5' },
            ];
        }
    }

    public update() {
        ExternalDataBus.request('GET_USERS_LIST')
            // @ts-ignore
            .then(this.set)
            .catch((e) => console.error('Users update failed', e));
    }

    private set = (users: UsersType) => {
        console.log('set', users);
        this.list = users || [];
        window.dispatchEvent(new CustomEvent('users_update'));
    };
}

// window.addEventListener('message', (e) => {
//     try {
//         const { tag, id, response } = JSON.parse(e.data);
//
//         if (tag === 'GET_USERS_LIST' && !response) {
//             window.postMessage(
//                 JSON.stringify({
//                     data: [
//                         { id: 1, name: 'Test1' },
//                         { id: 2, name: 'Test2' },
//                         { id: 3, name: 'Test3' },
//                         { id: 4, name: 'Test4' },
//                         { id: 5, name: 'Test5' },
//                     ],
//                     id,
//                     response: true,
//                 }),
//             );
//         }
//     } catch (e) {}
// });

export default new Users();
