import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { MessageType } from '@sscale/chatsdk';

import Chat from 'Services/Chat';
import { isIframeMode } from 'Services/Params';
import throttle from 'Utils/throttle';
import useForceUpdate from 'Hooks/useForceUpdate';

import Message from './Message';

import style from './style.module.css';

const Messages: React.FC<{ roomId: string }> = ({ roomId }) => {
    const forceUpdate = useForceUpdate();
    const ref = useRef<HTMLDivElement>(null);

    // Scroll to bottom forced or if user is on last message
    const scrollToBottom = useCallback((forced: boolean = false) => {
        const $container = ref.current;
        if (!$container) {
            return;
        }

        const offset = $container.scrollHeight - $container.scrollTop - $container.clientHeight;
        if (offset < 150 || forced) {
            $container.scrollTop = $container.scrollHeight;
        }
    }, []);

    // Load more messages on scroll
    const handleScroll = useCallback(() => {
        const $container = ref.current;
        if (!$container) {
            return;
        }

        const top = $container.scrollTop === 0;
        if (!top) {
            return;
        }

        if (!Chat.room(roomId)?.history.hasOlder) {
            return;
        }

        console.log('Loading older messages');

        const scrollHeight = $container.scrollHeight;

        Chat.room(roomId)
            ?.history.loadOlder()
            .then(() => {
                forceUpdate();
                $container.scrollTop = $container.scrollHeight - scrollHeight;
                console.log('History updated, older messages loaded');
            })
            .catch((e: any) => {
                console.log('Older messages load error', e);
            });
    }, [roomId]);

    useEffect(() => {
        const handleResize = throttle(() => {
            if (isIframeMode()) {
                scrollToBottom(true);
            }
        }, 150);
        
        window.addEventListener('resize', handleResize);

          return () => {
              window.removeEventListener('resize', handleResize);
          }
    }, []);

    // Subscribe to history updates
    useEffect(() => {
        const room = Chat.room(roomId);

        if (!room) {
            return;
        }

        const onMessage = (msg: MessageType) => {
            forceUpdate();
            scrollToBottom(msg.author === Chat.user?.id);
        };

        room.history.on(room.history.Events.MESSAGE, onMessage);
        room.on(room.Events.PARTICIPANT_META_UPDATED, forceUpdate);

        return () => {
            room.history.off(room.history.Events.MESSAGE, onMessage);
            room.off(room.Events.PARTICIPANT_META_UPDATED, forceUpdate);
        };
    }, [roomId]);

    // Initial scroll and history load
    useEffect(() => {
        const room = Chat.room(roomId);

        if (!room) {
            return;
        }

        const initialScroll = () => {
            const $unreadLabel = document.getElementById('unread');
            const $container = ref.current;
            if ($unreadLabel && $container) {
                $container.scrollTop = $unreadLabel.offsetTop - 20;
            } else {
                scrollToBottom(true);
            }
        };

        // load all unread messages and scroll to correct position
        if (!room.history.unreadLoaded()) {
            room.history
                .loadUnread()
                .then(() => {
                    forceUpdate();
                    initialScroll();
                    console.log('Unread messages loaded');
                })
                .catch((e: any) => {
                    console.log('Unread messages load error', e);
                });

            // load last messages and scroll to correct position
        } else if (!room.history.messages[0] && room.history.hasOlder()) {
            room.history
                .loadOlder()
                .then(() => {
                    forceUpdate();
                    scrollToBottom(true);
                    console.log('Initial history loaded');
                })
                .catch((e: any) => {
                    console.log('History load error', e);
                });

            // scroll to correct position
        } else {
            initialScroll();
        }
    }, [roomId]);

    const memoizedLastReadByMe = useMemo(() => Chat.room(roomId)?.history.lastReadByMe(), [roomId]);

    const room = Chat.room(roomId);

    if (!room) {
        return null;
    }

    return (
        <div className={style.root} ref={ref} onScroll={handleScroll}>
            {room.history.unreadLoaded() &&
                room.history.messages.map((item, i) => (
                    <Message
                        key={item.id}
                        roomId={item.room}
                        author={room.meta(item.author)?.username || item.author}
                        isMy={Chat.user?.id === item.author}
                        text={item.content}
                        time={item.created_at}
                        timePrev={room.history.messages[i - 1]?.created_at}
                        hideDayLabel={i === 0 && room.history.hasOlder()}
                        memoizedLastReadByMe={memoizedLastReadByMe}
                    />
                ))}
        </div>
    );
};

export default React.memo(Messages);
