import ExternalDataBus from './ExternalDataBus';
import Chat from './Chat';
import { ChatEvents, HistoryEvents } from '@sscale/chatsdk';

const pending: (() => void)[] = [];

const handleConfAddUsers = (data: any) => {
    console.log('handleConfAddUsers', data);

    const addUsers = () => {
        const room = Chat.rooms.find((room) => room.name === data.room);
        room?.addMembers(data.users || []);
    };

    if (Chat.state !== Chat.States.CONNECTED) {
        pending.push(addUsers);
    } else {
        addUsers();
    }
};

ExternalDataBus.on('CONF_ADD_USERS', handleConfAddUsers);

Chat.on(ChatEvents.CONNECT, () => {
    ExternalDataBus.post('CONNECTED');

    pending.forEach((operation) => operation());
});

Chat.on(HistoryEvents.MESSAGE, (message) => {
    const own = Chat.user?.id === message.author;
    if (own) {
        return;
    }

    const username = Chat.room(message.room)?.meta(message.author)?.username || '';
    ExternalDataBus.post('MESSAGE', {...message, username});
});
