import React, { useEffect, useRef } from 'react';
import { Done as DoneIcon, DoneAll as DoneAllIcon } from '@mui/icons-material';
import classNames from 'classnames';

import Chat from 'Services/Chat';
import { isTouch } from 'Services/Device';

import useForceUpdate from 'Hooks/useForceUpdate';
import useDateLabel from 'Hooks/useDateLabel';
import useObserver from 'Hooks/useObserver';

import style from './style.module.css';

const Message: React.FC<{
    roomId: string;
    text: any;
    time: string;
    timePrev?: string;
    author: string;
    isMy: boolean;
    hideDayLabel: boolean;
    memoizedLastReadByMe?: string;
}> = ({
    roomId,
    text,
    time,
    timePrev = new Date(0).toISOString(),
    author,
    isMy,
    hideDayLabel,
    memoizedLastReadByMe = new Date(0).toISOString(),
}) => {
    const forceUpdate = useForceUpdate();
    const { observe, unobserve } = useObserver();
    const ref = useRef<HTMLDivElement>(null);

    const room = Chat.room(roomId);
    const isRead = room?.history.isRead(time);
    const isReadByMe = room?.history.isReadByMe(time);

    // Mark as read when window is in focus and message is visible
    useEffect(() => {
        if (isMy || isReadByMe) {
            return;
        }

        const $el = ref.current;
        const $container = $el?.parentElement;
        if (!$el || !$container) {
            return;
        }

        const cleanup = () => {
            window.removeEventListener('focus', onFocus);
            unobserve($container, $el);
        };

        const onFocus = () => {
            unobserve($container, $el);
            observerSub();
        };

        const observerSub = () => {
            observe($container, $el, (isVisible: boolean) => {
                if (!isVisible || !document.hasFocus()) {
                    return;
                }

                room?.history
                    .markAsRead(time)
                    .then(cleanup)
                    .catch((e) => console.error('Mark as read failure:', e));
            });
        };

        observerSub();
        window.addEventListener('focus', onFocus);

        return cleanup;
    }, [isReadByMe]);

    // Message popover
    useEffect(() => {
        if (!isMy) {
            return;
        }

        const $el = ref.current;
        if (!$el) {
            return;
        }

        const handleClick = (e: any) => {
            e.preventDefault();

            window.dispatchEvent(new CustomEvent('msg_popover', { detail: { x: e.x, y: e.y, timestamp: time } }));
        };

        if (isTouch()) {
            $el.addEventListener('click', handleClick);
        } else {
            $el.addEventListener('contextmenu', handleClick);
        }

        return () => {
            $el.removeEventListener('click', handleClick);
            $el.removeEventListener('contextmenu', handleClick);
        };
    }, []);

    // Read status updates listeners
    useEffect(() => {
        if (isMy && isRead) {
            return;
        }

        if (!isMy && isReadByMe) {
            return;
        }

        if (!room) {
            return;
        }

        console.log('read status update sub');

        room.history.on(room.history.Events.READ_STATUS_UPDATED, forceUpdate);

        return () => {
            room.history.off(room.history.Events.READ_STATUS_UPDATED, forceUpdate);
        };
    }, [isRead, isReadByMe, isMy]);

    const msgDate = new Date(time);
    const h = String(msgDate.getHours()).padStart(2, '0');
    const m = String(msgDate.getMinutes()).padStart(2, '0');
    const dayLabel = useDateLabel(time);

    if (!room) {
        return null;
    }

    const showLastReadLabel = timePrev <= memoizedLastReadByMe && time > memoizedLastReadByMe;

    return (
        <>
            {!isMy && showLastReadLabel && (
                <div className={style.unreadWrapper} id="unread">
                    <div className={style.unread}>Unread messages</div>
                </div>
            )}

            {!hideDayLabel && new Date(timePrev).toDateString() !== msgDate.toDateString() && (
                <div className={style.dateWrapper}>
                    <div className={style.date}>{dayLabel}</div>
                </div>
            )}

            <div ref={ref} className={classNames(style.root, { [style.rootMy]: isMy })}>
                {!isMy && (
                    <div
                        className={style.title}
                        style={{
                            justifyContent: isMy ? 'end' : 'start',
                        }}
                    >
                        {author}
                    </div>
                )}

                <div className={style.msg}>
                    <div className={classNames(style.content, { [style.contentMy]: isMy })}>{text}</div>
                    <div className={style.actions}>
                        <div className={style.actionsTime}>{`${h}:${m}`}</div>
                        {isMy &&
                            (isRead ? (
                                <DoneAllIcon
                                    className={style.actionsOk}
                                    fontSize="inherit"
                                    sx={{ marginBottom: '-3px' }}
                                />
                            ) : (
                                <DoneIcon
                                    className={style.actionsOk}
                                    fontSize="inherit"
                                    sx={{ marginBottom: '-3px' }}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(Message);
