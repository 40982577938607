import { useCallback, useState } from 'react';

function useForceUpdate() {
    const [, updateState] = useState() as any;
    const forceUpdate = useCallback(() => updateState({}), []);

    return forceUpdate;
}

export default useForceUpdate;
